
// src/components/pages/SignupPage.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import api from '../../services/apiService';

const SignupPage = () => {

  console.log(212);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    apiKey: '',
  });
  const [error, setError] = useState(null);

  const { email, password, apiKey } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/auth/register', {
        email,
        password,
        apiKey,
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      login(); // Update auth context
      navigate('/chat');
    } catch (err) {
      setError(err.response?.data?.error || 'Registration failed');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={email}
        onChange={onChange}
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        value={password}
        onChange={onChange}
      />
      <input
        type="text"
        placeholder="OpenAI API Key"
        name="apiKey"
        value={apiKey}
        onChange={onChange}
      />
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <button type="submit">Sign Up</button>
      <div>
        <a href="http://localhost:4000/api/v1/auth/google">
          Sign Up with Google
        </a>
      </div>
    </form>
  );
};

export default SignupPage;
