// src/pages/SubscriptionPage.js
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../services/apiService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const SubscriptionPage = () => {
  // To prevent double recording in development with React Strict Mode:
  const [hasRecorded, setHasRecorded] = useState(false);

  useEffect(() => {
    const recordVisit = async () => {
      try {
        if (!hasRecorded) {
          await api.post('/subscription/record-subscription-page-visit');
          setHasRecorded(true);
        }
      } catch (err) {
        console.error('Error recording subscription page visit:', err);
      }
    };

    recordVisit();
  }, [hasRecorded]);

  const handleSubscribe = async () => {
    try {
      // Call backend to create the Checkout Session
      const response = await api.post('/payment/create-checkout-session');
      const { sessionId } = response.data;

      const stripe = await stripePromise;
      // Redirect to Stripe Checkout
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error('Error during subscription:', err);
      // Handle error accordingly
    }
  };

  return (
    <div>
      <h1>Upgrade Your Account</h1>
      <button onClick={handleSubscribe}>Subscribe Now</button>
    </div>
  );
};

export default SubscriptionPage;
