// src/services/apiService.js
import axios from 'axios';

const API_URL = 'http://localhost:4000/api/v1';
// const API_URL = 'https://9217-34-74-191-161.ngrok-free.app';

const api = axios.create({
  baseURL: API_URL,
});

export default api;
