// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/layout/Navbar'; // Import Navbar
import LoginPage from './components/pages/LoginPage';
import SignupPage from './components/pages/SignupPage';
import ChatPage from './components/pages/ChatPage';
import GoogleAuthSuccess from './components/pages/GoogleAuthSuccess';
import PrivateRoute from './components/common/PrivateRoute';
import SubscriptionPage from './components/pages/SubscriptionPage';
import SuccessPage from './components/pages/SuccessPage';
import SubscriptionManagementPage from './components/pages/SubscriptionManagementPage';


console.log(process.env.REACT_APP_FRONTEND_URL);
console.log(process.env.REACT_APP_LMARHALA);

// if (process.env.REACT_APP_LMARHALA===0) {
// const ChatPageRoute=
// }
// else {
//   const ChatPageRoute=
// }


function App() {
  return (
    <AuthProvider>
      <Router>
        <NavbarWrapper /> {/* Conditionally render Navbar */}
        <Routes>
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/google/success" element={<GoogleAuthSuccess />} />
          
         
          {process.env.REACT_APP_LMARHALA!=0?<Route path="/chat" element={<PrivateRoute><ChatPage /></PrivateRoute>}/>:<Route path="/chat" element={<ChatPage />}/>}
          
          <Route
            path="/subscribe"
            element={
              <PrivateRoute>
                <SubscriptionPage />
              </PrivateRoute>
            }
          />
          
          <Route
            path="/success"
            element={
              <PrivateRoute>
                <SuccessPage />
              </PrivateRoute>
            }
          />
          
          <Route
            path="/subscription"
            element={
              <PrivateRoute>
                <SubscriptionManagementPage />
              </PrivateRoute>
            }
          />
          
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

/**
 * NavbarWrapper Component
 * 
 * This component uses the useLocation hook to determine the current path.
 * It conditionally renders the Navbar based on whether the current path
 * matches any of the paths where the Navbar should be hidden.
 */
function NavbarWrapper() {
  const location = useLocation();

  // Define paths where Navbar should be hidden
  const hideNavbarPaths = ['/chat'];

  // Check if the current path is in the hideNavbarPaths array
  const shouldHideNavbar = hideNavbarPaths.includes(location.pathname);

  return shouldHideNavbar ? null : <Navbar />;
}

export default App;
