// src/components/pages/SuccessPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/apiService';

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await api.get('/user/subscription-status');
        setSubscriptionActive(response.data.active);
      } catch (err) {
        console.error('Error fetching subscription status:', err);
      } finally {
        setLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!subscriptionActive) {
    return (
      <div>
        <h1>Payment Pending</h1>
        <p>Your payment is being processed. Please wait a moment.</p>
      </div>
    );
  }

  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>Your subscription is now active.</p>
      <button onClick={() => navigate('/chat')}>Go to Chat</button>
    </div>
  );
};

export default SuccessPage;
