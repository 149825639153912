// src/components/pages/SubscriptionManagementPage.js

import React, { useEffect, useState } from 'react';
import api from '../../services/apiService';
import { useNavigate } from 'react-router-dom';

const SubscriptionManagementPage = () => {
  const [subscription, setSubscription] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await api.get('/subscription');
        setSubscription(response.data.subscription);
      } catch (err) {
        console.error('Error fetching subscription:', err);
      }
    };

    fetchSubscription();
  }, []);

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        await api.post('/subscription/cancel');
        alert('Your subscription has been scheduled for cancellation.');
        // Optionally, refresh the subscription status without navigating away
        const updatedSubscription = await api.get('/subscription');
        setSubscription(updatedSubscription.data.subscription);
      } catch (err) {
        console.error('Error cancelling subscription:', err);
        alert('Failed to cancel subscription. Please try again.');
      }
    }
  };

  if (!subscription) {
    return <div>Loading...</div>;
  }

  const { status, cancelAtPeriodEnd, endDate } = subscription;

  return (
    <div>
      <h1>Manage Your Subscription</h1>
      {status === 'active' && cancelAtPeriodEnd && endDate ? (
        <div>
          <p>
            Your subscription is scheduled to be canceled on{' '}
            {new Date(endDate).toLocaleDateString()}.
          </p>
          <p>You can continue to use our services until then.</p>
        </div>
      ) : status === 'active' ? (
        <div>
          <p>Status: Active</p>
          <button onClick={handleCancelSubscription}>Cancel Subscription</button>
        </div>
      ) : status === 'incomplete' ? (
        <div>
          <p>Your subscription is being set up. Please complete your payment.</p>
          {/* Optionally, provide a link to complete payment */}
          <button onClick={() => navigate('/complete-payment')}>Complete Payment</button>
        </div>
      ) : status === 'canceled' ? (
        <div>
          <p>Your subscription has been canceled.</p>
          {/* Optionally, provide a button to resubscribe */}
          <button onClick={() => navigate('/subscribe')}>Resubscribe</button>
        </div>
      ) : (
        <div>
          <p>Status: {status}</p>
          {status !== 'canceled' && (
            <button onClick={handleCancelSubscription}>Cancel Subscription</button>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionManagementPage;
