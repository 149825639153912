// src/components/pages/ChatPage.js

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation
import api from '../../services/apiService';
import Modal from 'react-modal';
import './ChatPage.css';
import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext
import ReviewModal from '../Modals/ReviewModal';
import HelpModal from '../Modals/HelpModal';
import ShareModal from '../Modals/ShareModal';
import CryptoJS from 'crypto-js'; // Import CryptoJS for encryption

// Access the encryption key from environment variables
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

// Helper functions for encryption and decryption
const encryptData = (data) => {
  if (!encryptionKey) {
    throw new Error('Encryption key is missing. Please set REACT_APP_ENCRYPTION_KEY in your .env file.');
  }
  return CryptoJS.AES.encrypt(data, encryptionKey).toString();
};

const decryptData = (cipherText) => {
  if (!encryptionKey) {
    throw new Error('Encryption key is missing. Please set REACT_APP_ENCRYPTION_KEY in your .env file.');
  }
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, encryptionKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (!decrypted) {
      throw new Error('Decryption failed. Invalid ciphertext or encryption key.');
    }
    return decrypted;
  } catch (error) {
    console.error('Error during decryption:', error);
    throw new Error('Failed to decrypt data.');
  }
};

const ChatPage = () => {
  // Access the current user from AuthContext
  var { user } = useContext(AuthContext);

  // If not authenticated, assign a fallback user ID
  if (user === null) {
    user = { id: "777777777", iat: 1733253711 };
  }

  console.log("Current user:", user);

  // State variables
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Initialized useLocation

  // State to track if limit is reached
  const [isLimitReached, setIsLimitReached] = useState(false);

  // **NEW: State Variables for Click Limits**
  const [currentClickCount, setCurrentClickCount] = useState(0);
  const [clickLimit, setClickLimit] = useState(0);

  // Track if directory was selected
  const [hasDirectorySelected, setHasDirectorySelected] = useState(false);

  // File system handles
  const [directoryHandle, setDirectoryHandle] = useState(null);
  const [conversationsHandle, setConversationsHandle] = useState(null);
  const [foldersHandle, setFoldersHandle] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);

  // State for managing uploaded images
  const [uploadedImages, setUploadedImages] = useState([]);

  // Refs
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const iframeRef = useRef(null);
  const uploadFileInputRef = useRef(null); // Ref for per-folder uploads

  // Modal states
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  // Modal states for renaming conversations
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameConversation, setRenameConversation] = useState(null);
  const [newConversationName, setNewConversationName] = useState('');

  // Folder and File States
  const [folders, setFolders] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [filesInFolders, setFilesInFolders] = useState({});

  // Mappings between image paths and Blob URLs
  const imagePathToBlobURLRef = useRef(new Map());
  const blobURLToImagePathRef = useRef(new Map());

  // Loading states
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  // State for index.html content
  const [indexContent, setIndexContent] = useState(null);

  // State for tracking current upload folder
  const [currentUploadFolder, setCurrentUploadFolder] = useState(null);

  // State Variables for Collapsible and Load More
  const [isConversationsCollapsed, setIsConversationsCollapsed] = useState(false);
  const [displayedConversations, setDisplayedConversations] = useState(7);

  const [isFoldersCollapsed, setIsFoldersCollapsed] = useState(false);
  const [displayedFolders, setDisplayedFolders] = useState(7);

  // State to manage favorite conversations as an array of names
  const [favoriteConversations, setFavoriteConversations] = useState([]);

  // State to manage collapsing of Favorite Apps section
  const [isFavoriteAppsCollapsed, setIsFavoriteAppsCollapsed] = useState(false);

  // **NEW: State Variables for OpenAI API Key**
  const [openaiApiKey, setOpenaiApiKey] = useState(localStorage.getItem('openaiApiKey') || '');
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [keyInput, setKeyInput] = useState('');

  // **NEW: State Variable to Track if Authenticated User Has API Key**
  const [hasApiKey, setHasApiKey] = useState(false);

  // **NEW: State Variable for Tracking if Review Modal Has Been Shown**
  const [hasShownReviewModal, setHasShownReviewModal] = useState(() => {
    return JSON.parse(localStorage.getItem(`hasShownReviewModal_${user.id}`)) || false;
  });

  const [reviewModalShow, setreviewModalShow] = useState(false);
  const [helpModalShow, sethelpModalShow] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const shareUrl = 'https://yobino.com'; // Replace with your app's URL
  const title = 'Check out this awesome app!';
  const description = 'This app does amazing things. You should definitely try it out!';

  const handleOpenReview = () => setreviewModalShow(true);
  const handleCloseReview = () => setreviewModalShow(false);

  const handleOpenHelp = () => sethelpModalShow(true);
  const handleCloseHelp = () => sethelpModalShow(false);

  // **NEW: Ref to track the latest number of conversations**
  const conversationCountRef = useRef(0);

  // Update the ref whenever conversations change
  useEffect(() => {
    conversationCountRef.current = conversations.length;
  }, [conversations.length]);

  // Scroll to the latest message
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Cleanup object URLs on unmount
  useEffect(() => {
    return () => {
      uploadedImages.forEach((img) => URL.revokeObjectURL(img.url));
      imagePathToBlobURLRef.current.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [uploadedImages]);

  // **NEW: Helper functions for encryption and decryption**
  const readEncryptedFile = async (fileHandle) => {
    const file = await fileHandle.getFile();
    const encryptedContent = await file.text();
    const decryptedContent = decryptData(encryptedContent);
    return decryptedContent;
  };

  const writeEncryptedFile = async (fileHandle, data) => {
    const encryptedContent = encryptData(data);
    const writable = await fileHandle.createWritable();
    await writable.write(encryptedContent);
    await writable.close();
  };

  // Effect to auto-save conversations when messages change
  useEffect(() => {
    const saveConversation = async () => {
      if (currentConversation && conversationsHandle) {
        try {
          const conversationFolderHandle = await conversationsHandle.getDirectoryHandle(currentConversation.name);
          const fileHandle = await conversationFolderHandle.getFileHandle('conversation.json', { create: true });

          const originalMessages = messages.map(({ sender, content, timestamp }) => ({
            sender,
            content,
            timestamp,
          }));

          let allowedUsers = [];
          try {
            // Read existing conversation.json with decryption
            const decryptedContent = await readEncryptedFile(fileHandle);
            const existingData = JSON.parse(decryptedContent);
            allowedUsers = existingData.allowedUsers || [];
          } catch (err) {
            console.warn('conversation.json does not exist or is malformed. Initializing allowedUsers.');
          }

          const conversationData = {
            allowedUsers,
            messages: originalMessages,
          };

          // Encrypt and write to conversation.json
          await writeEncryptedFile(fileHandle, JSON.stringify(conversationData, null, 2));
          console.log(`Conversation "${currentConversation.name}" saved automatically.`);
        } catch (err) {
          console.error('Error auto-saving conversation:', err);
        }
      }
    };

    saveConversation();
  }, [messages, currentConversation, conversationsHandle]);

  // Check user limit
  const checkUserLimit = async () => {
    try {
      const response = await api.get('/user/userStatus');
      const { isSubscribed, currentRequestCount, requestLimit, isLimitReached: limitReachedBackend, currentClickCount: clickCountBackend, clickLimit: clickLimitBackend } = response.data;

      setIsLimitReached(limitReachedBackend);
      setCurrentClickCount(clickCountBackend);
      setClickLimit(clickLimitBackend);
      console.log(`User subscribed: ${isSubscribed}`);
      console.log(`Current request count: ${currentRequestCount}`);
      console.log(`Request limit: ${requestLimit}`);
      console.log(`Current click count: ${clickCountBackend}`);
      console.log(`Click limit: ${clickLimitBackend}`);
    } catch (err) {
      console.error('Error checking user limit:', err);
    }
  };

  // Fetch API Key Status for Authenticated Users
  useEffect(() => {
    const fetchApiKeyStatus = async () => {
      if (user.id !== "777777777") { 
        try {
          const response = await api.get('/user/apiKeyStatus');
          const { hasApiKey: backendHasApiKey } = response.data;
          setHasApiKey(backendHasApiKey);
          console.log(`Authenticated user has API key: ${backendHasApiKey}`);
        } catch (err) {
          console.error('Error fetching API key status:', err);
        }
      }
    };

    fetchApiKeyStatus();
  }, [user.id]);

  // Helper function to load favorites from 'favorites.json'
  const loadFavorites = async (dirHandle, userId) => {
    try {
      const favoritesFileHandle = await dirHandle.getFileHandle('favorites.json');
      
      // Read and decrypt favorites.json
      const decryptedContent = await readEncryptedFile(favoritesFileHandle);
      const allFavorites = JSON.parse(decryptedContent);

      if (typeof allFavorites !== 'object' || allFavorites === null) {
        throw new Error('favorites.json is not an object.');
      }

      // Migrate favorites from '777777777' to the authenticated user if applicable
      if (userId !== "777777777" && allFavorites["777777777"]) {
        const oldFavorites = Array.isArray(allFavorites["777777777"]) ? allFavorites["777777777"] : [];
        if (!Array.isArray(allFavorites[userId])) {
          allFavorites[userId] = [];
        }
        // Merge and remove duplicates
        allFavorites[userId] = Array.from(new Set([...allFavorites[userId], ...oldFavorites]));
        delete allFavorites["777777777"];

        // Re-save the updated favorites.json
        await writeEncryptedFile(favoritesFileHandle, JSON.stringify(allFavorites, null, 2));
        console.log(`Migrated favorites from "777777777" to "${userId}".`);
      }

      const userFavorites = Array.isArray(allFavorites[userId]) ? allFavorites[userId] : [];
      setFavoriteConversations(userFavorites);
      console.log(`Favorites loaded for user ${userId}:`, userFavorites);
    } catch (err) {
      if (err.name === 'NotFoundError') {
        // No favorites.json found, initialize empty
        setFavoriteConversations([]);
        console.log('favorites.json not found. Initializing empty favorites for this user.');
      } else if (err.message === 'Failed to decrypt data.') {
        // Handle decryption failure (possibly unencrypted favorites.json)
        console.error('Failed to decrypt favorites.json. It may be unencrypted or corrupted.');
        alert('Failed to decrypt favorites.json. Please ensure it is properly encrypted or reset the favorites.');
        setFavoriteConversations([]);
      } else {
        console.error('Error loading favorites:', err);
        alert('Failed to load favorites. Please check the favorites.json file format.');
        setFavoriteConversations([]);
      }
    }
  };

  // Helper function to save favorites
  const saveFavorites = async (dirHandle, userId, favorites) => {
    if (!dirHandle) return;
    try {
      let allFavorites = {};
      try {
        const favoritesFileHandle = await dirHandle.getFileHandle('favorites.json');
        // Read and decrypt existing favorites.json
        const decryptedContent = await readEncryptedFile(favoritesFileHandle);
        allFavorites = JSON.parse(decryptedContent);
        if (typeof allFavorites !== 'object' || allFavorites === null) {
          allFavorites = {};
        }
      } catch (err) {
        if (err.name !== 'NotFoundError') {
          console.error('Error reading existing favorites:', err);
        }
        // If not found or invalid, start fresh
        allFavorites = {};
      }

      // Update only this user's favorites
      allFavorites[userId] = favorites;

      const favoritesFileHandle = await dirHandle.getFileHandle('favorites.json', { create: true });
      // Encrypt and write to favorites.json
      await writeEncryptedFile(favoritesFileHandle, JSON.stringify(allFavorites, null, 2));
      console.log(`Favorites saved for user ${userId}:`, favorites);
    } catch (err) {
      console.error('Error saving favorites:', err);
      alert('Failed to save favorites.');
    }
  };

  // Toggle favorite conversation
  const toggleFavoriteConversation = async (conversation) => {
    const isFavorite = favoriteConversations.includes(conversation.name);
    let updatedFavorites;

    if (isFavorite) {
      updatedFavorites = favoriteConversations.filter((favName) => favName !== conversation.name);
    } else {
      updatedFavorites = [...favoriteConversations, conversation.name];
    }

    setFavoriteConversations(updatedFavorites);
    console.log(isFavorite ? `Removed "${conversation.name}" from favorites for user ${user.id}.` : `Added "${conversation.name}" to favorites for user ${user.id}.`);

    // Save favorites for this user
    if (directoryHandle) {
      await saveFavorites(directoryHandle, user.id, updatedFavorites);
    }
  };

  // Remove from favorites
  const removeFavoriteConversation = async (conversationName) => {
    const updatedFavorites = favoriteConversations.filter((favName) => favName !== conversationName);
    setFavoriteConversations(updatedFavorites);
    console.log(`Removed "${conversationName}" from favorites for user ${user.id}.`);

    // Save favorites for this user
    if (directoryHandle) {
      await saveFavorites(directoryHandle, user.id, updatedFavorites);
    }
  };

  // Load Conversations with decryption
  const loadConversations = async (conversationsDirHandle) => {
    try {
      const convs = [];
      for await (const entry of conversationsDirHandle.values()) {
        if (entry.kind === 'directory') {
          try {
            const convoFolderHandle = entry;
            const convoFileHandle = await convoFolderHandle.getFileHandle('conversation.json');
            const decryptedContent = await readEncryptedFile(convoFileHandle);
            const convoData = JSON.parse(decryptedContent);

            let shouldInclude = false;

            if (convoData.allowedUsers && convoData.allowedUsers.includes(user.id)) {
              shouldInclude = true;
            } else if (convoData.allowedUsers && convoData.allowedUsers.includes("777777777")) {
              // Replace the placeholder user with the current user if present
              convoData.allowedUsers = convoData.allowedUsers.map(uid => uid === "777777777" ? user.id : uid);
              await writeEncryptedFile(convoFileHandle, JSON.stringify(convoData, null, 2));
              shouldInclude = true;
              console.log(`Replaced "777777777" with "${user.id}" in conversation "${entry.name}".`);
            }

            if (shouldInclude) {
              const convoFile = await convoFileHandle.getFile();
              const lastModified = convoFile.lastModified; 
              convs.push({
                name: entry.name,
                folderHandle: convoFolderHandle,
                lastModified,
              });
            }
          } catch (err) {
            console.warn(`conversation.json not found or malformed for ${entry.name}. Skipping this conversation.`);
          }
        }
      }
      convs.sort((a, b) => b.lastModified - a.lastModified);
      setConversations(convs);
      setDisplayedConversations(7);
    } catch (err) {
      console.error('Error loading conversations:', err);
      alert('Failed to load conversations.');
    }
  };

  // Load Uploaded Images
  const loadUploadedImages = async (foldersDirHandle) => {
    try {
      let imagesDirHandle;
      try {
        imagesDirHandle = await foldersDirHandle.getDirectoryHandle('images');
      } catch (err) {
        console.warn("'images' directory does not exist. Creating it.");
        imagesDirHandle = await foldersDirHandle.getDirectoryHandle('images', { create: true });
      }

      const imageFiles = [];
      for await (const entry of imagesDirHandle.values()) {
        if (entry.kind === 'file' && entry.name.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i)) {
          imageFiles.push(entry);
        }
      }

      const images = [];
      const imagePathToBlobURL = new Map();
      const blobURLToImagePathMap = new Map();

      for (const fileHandle of imageFiles) {
        try {
          const file = await fileHandle.getFile();
          const imageURL = URL.createObjectURL(file);
          const relativePath = `/images/${file.name}`;
          images.push({ name: file.name, path: relativePath, url: imageURL });
          imagePathToBlobURL.set(relativePath, imageURL);
          blobURLToImagePathMap.set(imageURL, relativePath);
          console.log(`Loaded image: ${relativePath} -> ${imageURL}`);
        } catch (err) {
          console.error(`Error reading image file ${fileHandle.name}:`, err);
        }
      }

      return { images, imagePathToBlobURL, blobURLToImagePathMap };
    } catch (err) {
      console.error('Error loading uploaded images:', err);
      alert('Failed to load uploaded images.');
      return { images: [], imagePathToBlobURL: new Map(), blobURLToImagePathMap: new Map() };
    }
  };

  // Load Folders
  const loadFolders = async (foldersDirHandle) => {
    try {
      const folderEntries = [];
      for await (const entry of foldersDirHandle.values()) {
        if (entry.kind === 'directory') {
          try {
            const folderFileHandle = await entry.getFileHandle('folder.json');
            const folderFile = await folderFileHandle.getFile();
            const decryptedContent = await readEncryptedFile(folderFileHandle);
            const folderData = JSON.parse(decryptedContent);
            const lastModified = folderData.lastModified || folderFile.lastModified || 0;

            folderEntries.push({
              name: entry.name,
              folderHandle: entry,
              lastModified,
            });
          } catch (err) {
            console.warn(`folder.json not found or malformed for ${entry.name}. Using 0 as lastModified.`);
            folderEntries.push({
              name: entry.name,
              folderHandle: entry,
              lastModified: 0,
            });
          }
        }
      }
      folderEntries.sort((a, b) => b.lastModified - a.lastModified);
      setFolders(folderEntries);
      setDisplayedFolders(7);
    } catch (err) {
      console.error('Error loading folders:', err);
      alert('Failed to load folders.');
    }
  };

  // Helper to get or create a directory
  const getOrCreateDirectory = async (parentHandle, dirName) => {
    try {
      const dirHandle = await parentHandle.getDirectoryHandle(dirName);
      return dirHandle;
    } catch (err) {
      const newDirHandle = await parentHandle.getDirectoryHandle(dirName, { create: true });
      console.log(`Created directory: ${dirName}`);
      return newDirHandle;
    }
  };

  // Select Root Directory
  const handleSelectDirectory = async () => {
    if (!window.showDirectoryPicker) {
      alert('File System Access API is not supported in this browser.');
      return;
    }

    try {
      const dirHandle = await window.showDirectoryPicker();
      setDirectoryHandle(dirHandle);
      setHasDirectorySelected(true); // Start counting clicks after directory selection
      console.log('Root directory selected:', dirHandle.name);

      const conversationsDirHandle = await getOrCreateDirectory(dirHandle, 'conversations');
      const foldersDirHandle = await getOrCreateDirectory(dirHandle, 'folders');
      setConversationsHandle(conversationsDirHandle);
      setFoldersHandle(foldersDirHandle);

      // Load conversations
      await loadConversations(conversationsDirHandle);
      console.log('Conversations loaded.');

      // Load favorites for the current user
      await loadFavorites(dirHandle, user.id);
      console.log('Favorites loaded for current user.');

      // Ensure images folder
      await getOrCreateDirectory(foldersDirHandle, 'images');

      // Load images
      setIsLoadingImages(true);
      const { images, imagePathToBlobURL, blobURLToImagePathMap } = await loadUploadedImages(foldersDirHandle);
      setUploadedImages(images);
      imagePathToBlobURLRef.current = imagePathToBlobURL;
      blobURLToImagePathRef.current = blobURLToImagePathMap;
      setIsLoadingImages(false);
      console.log('Images loaded and mappings established.');

      // Load other folders
      await loadFolders(foldersDirHandle);
      console.log('Folders loaded.');

      // Check user limit
      await checkUserLimit();
    } catch (err) {
      console.error('Error selecting directory:', err);
      alert('Failed to select directory.');
    }
  };

  // **NEW: useEffect to Show Review Modal at 10 Conversations**
  useEffect(() => {
    if (conversations.length >= 10 && !hasShownReviewModal) { // Changed threshold to 10
      setreviewModalShow(true);
      setHasShownReviewModal(true);
      localStorage.setItem(`hasShownReviewModal_${user.id}`, JSON.stringify(true));
      console.log('Review modal triggered for reaching 10 conversations.');
    }
  }, [conversations.length, hasShownReviewModal, user.id]);

  // Create New Conversation
  const handleCreateConversation = async () => {
    if (!conversationsHandle) {
      alert('Please select a directory first.');
      return;
    }

    const convoName = window.prompt('Enter a name for the new conversation:', `Conversation ${conversations.length + 1}`);
    if (!convoName) {
      alert('Conversation name is required.');
      return;
    }

    try {
      await conversationsHandle.getDirectoryHandle(convoName, { create: false });
      alert('A conversation with this name already exists.');
      return;
    } catch (err) {
      if (err.name !== 'NotFoundError') {
        console.error('Error checking conversation existence:', err);
        alert('Failed to create conversation.');
        return;
      }
    }

    try {
      const folderHandle = await conversationsHandle.getDirectoryHandle(convoName, { create: true });

      const conversationData = {
        allowedUsers: [user.id],
        messages: [],
      };

      const fileHandle = await folderHandle.getFileHandle('conversation.json', { create: true });
      await writeEncryptedFile(fileHandle, JSON.stringify(conversationData, null, 2));

      const newConversation = {
        name: convoName,
        folderHandle: folderHandle,
        lastModified: Date.now(),
      };
      setConversations((prev) => [newConversation, ...prev]);
      setCurrentConversation(newConversation);
      setMessages([]);
      setIndexContent(null);
      setDisplayedConversations((prev) => prev + 1);
      alert(`Conversation "${convoName}" created successfully.`);
      console.log(`Created new conversation: ${convoName}`);

      await checkUserLimit();
    } catch (err) {
      console.error('Error creating conversation:', err);
      alert('Failed to create conversation.');
    }
  };

  // Rename Conversation Modal Handlers
  const openRenameConversationModal = (conversation) => {
    setRenameConversation(conversation);
    setNewConversationName(conversation.name);
    setIsRenameModalOpen(true);
  };

  const closeRenameConversationModal = () => {
    setIsRenameModalOpen(false);
    setRenameConversation(null);
    setNewConversationName('');
  };

  const handleRenameConversation = async () => {
    if (!newConversationName.trim()) {
      alert('Conversation name cannot be empty.');
      return;
    }

    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
    if (invalidChars.test(newConversationName)) {
      alert('Conversation name contains invalid characters.');
      return;
    }

    try {
      await conversationsHandle.getDirectoryHandle(newConversationName, { create: false });
      alert('A conversation with this name already exists.');
      return;
    } catch (err) {
      if (err.name !== 'NotFoundError') {
        console.error('Error checking conversation existence:', err);
        alert('Failed to rename conversation.');
        return;
      }
    }

    try {
      const oldName = renameConversation.name;
      const newName = newConversationName.trim();

      const newFolderHandle = await conversationsHandle.getDirectoryHandle(newName, { create: true });

      const copyDirectoryRecursively = async (sourceHandle, destinationHandle) => {
        for await (const entry of sourceHandle.values()) {
          if (entry.kind === 'file') {
            const file = await entry.getFile();
            const newFileHandle = await destinationHandle.getFileHandle(entry.name, { create: true });
            const writable = await newFileHandle.createWritable();
            await writable.write(file);
            await writable.close();
          } else if (entry.kind === 'directory') {
            const newSubDirHandle = await destinationHandle.getDirectoryHandle(entry.name, { create: true });
            await copyDirectoryRecursively(entry, newSubDirHandle);
          }
        }
      };

      await copyDirectoryRecursively(renameConversation.folderHandle, newFolderHandle);
      console.log(`Copied contents from "${oldName}" to "${newName}".`);

      await conversationsHandle.removeEntry(oldName, { recursive: true });
      console.log(`Deleted old conversation folder: "${oldName}".`);

      setConversations((prevConvos) =>
        prevConvos.map((convo) =>
          convo.name === oldName ? { ...convo, name: newName, folderHandle: newFolderHandle } : convo
        )
      );

      if (favoriteConversations.includes(oldName)) {
        const updatedFavorites = favoriteConversations.map((fav) => (fav === oldName ? newName : fav));
        setFavoriteConversations(updatedFavorites);
        if (directoryHandle) {
          await saveFavorites(directoryHandle, user.id, updatedFavorites);
        }
      }

      if (currentConversation && currentConversation.name === oldName) {
        setCurrentConversation({ ...currentConversation, name: newName, folderHandle: newFolderHandle });
      }

      alert(`Conversation renamed from "${oldName}" to "${newName}" successfully.`);
      console.log(`Renamed conversation "${oldName}" to "${newName}".`);
      closeRenameConversationModal();

      await checkUserLimit();
    } catch (err) {
      console.error('Error renaming conversation:', err);
      alert('Failed to rename conversation.');
    }
  };

  // Delete Conversation
  const handleDeleteConversation = async (conversation) => {
    if (!window.confirm(`Are you sure you want to delete the conversation "${conversation.name}"?`)) {
      return;
    }

    try {
      await conversationsHandle.removeEntry(conversation.name, { recursive: true });
      setConversations((prevConvos) => prevConvos.filter((convo) => convo.name !== conversation.name));

      if (favoriteConversations.includes(conversation.name)) {
        const updatedFavorites = favoriteConversations.filter((fav) => fav !== conversation.name);
        setFavoriteConversations(updatedFavorites);
        if (directoryHandle) {
          await saveFavorites(directoryHandle, user.id, updatedFavorites);
        }
      }

      if (currentConversation && currentConversation.name === conversation.name) {
        setCurrentConversation(null);
        setMessages([]);
        setIndexContent(null);
      }

      alert(`Conversation "${conversation.name}" deleted successfully.`);
      console.log(`Deleted conversation "${conversation.name}".`);

      await checkUserLimit();
    } catch (err) {
      console.error('Error deleting conversation:', err);
      alert('Failed to delete conversation.');
    }
  };

  // Load a selected conversation
  const handleLoadConversation = async (conversation) => {
    try {
      const conversationFolderHandle = conversation.folderHandle;
      const fileHandle = await conversationFolderHandle.getFileHandle('conversation.json');
      const decryptedContent = await readEncryptedFile(fileHandle);
      const convoData = JSON.parse(decryptedContent);

      if (!convoData.allowedUsers || !convoData.allowedUsers.includes(user.id)) {
        alert('You are not authorized to access this conversation.');
        return;
      }

      const loadedMessages = convoData.messages;
      if (!Array.isArray(loadedMessages)) {
        throw new Error('Invalid conversation format.');
      }

      const mappedMessages = loadedMessages.map((msg) => {
        if (msg.sender === 'assistant') {
          const modifiedDisplayContent = replaceRelativePathsWithBlobURLs(msg.content);
          return { ...msg, displayContent: modifiedDisplayContent };
        }
        return { ...msg, displayContent: msg.content };
      });

      setMessages(mappedMessages);
      setCurrentConversation(conversation);
      setError(null);
      console.log(`Conversation "${conversation.name}" loaded.`);

      await getConversationIndexContent(conversation);
    } catch (err) {
      console.error('Error loading conversation:', err);
      alert('Failed to load conversation.');
      setIndexContent(null);
    }
  };

  const handleSelectConversation = async (conversation, index) => {
    if (isLimitReached && index >= 3) {
      return;
    }
    await handleLoadConversation(conversation);
  };

  // **Modified handleSend Function**
  const handleSend = async () => {
    if (!prompt.trim()) {
      setError('Please enter a valid prompt.');
      return;
    }

    if (!currentConversation) {
      setError('Please select or create a conversation first.');
      return;
    }

    // **Check if user has API Key, if not, open the modal and return**
    if (
      (user.id === "777777777" && !openaiApiKey) ||
      (user.id !== "777777777" && !hasApiKey)
    ) {
      // Instead of setting error, open the API key modal
      setIsKeyModalOpen(true);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const lastExchange = [];
      if (messages.length >= 2) {
        const lastUserMessage = messages[messages.length - 2];
        const lastAssistantMessage = messages[messages.length - 1];
        if (lastUserMessage.sender === 'user' && lastAssistantMessage.sender === 'assistant') {
          const processedAssistantContent = replaceBlobURLsWithPaths(lastAssistantMessage.displayContent);
          lastExchange.push(
            { ...lastUserMessage, content: lastUserMessage.content },
            { ...lastAssistantMessage, content: processedAssistantContent }
          );
        }
      }

      const userMessageContent = prompt;
      // Do not add the user message to messages yet

      const requestPayload = {
        prompt: userMessageContent,
        messages: lastExchange,
        conversationName: currentConversation.name,
      };

      if (user.id === "777777777") {
        requestPayload.k = openaiApiKey;
      }

      const response = await api.post('/request', requestPayload);

      const assistantCode = response.data.code;
      console.log(assistantCode);

      const modifiedCode = replaceRelativePathsWithBlobURLs(assistantCode);
      console.log(modifiedCode);

      const assistantMessage = {
        sender: 'assistant',
        content: assistantCode,
        displayContent: modifiedCode,
        timestamp: new Date().toISOString(),
      };

      // Add user message and assistant message after successful response
      const userMessage = {
        sender: 'user',
        content: userMessageContent,
        displayContent: userMessageContent,
        timestamp: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, userMessage, assistantMessage]);

      await saveAssistantCode(assistantCode);
      await getConversationIndexContent(currentConversation);

      await checkUserLimit();

      // Clear the prompt only after successful send
      setPrompt('');
    } catch (err) {
      if (err.response) {
        const errorMsg = err.response.data.error || 'An error occurred. Please try again.';
        setError(errorMsg);
        if (errorMsg === 'Request limit reached. Upgrade your account.') {
          setIsLimitReached(true);
        }
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
      // Do not clear the prompt, so the user can resend the message
    } finally {
      setLoading(false);
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const saveAssistantCode = async (codeContent) => {
    if (!directoryHandle) {
      alert('Please select a directory first.');
      return;
    }

    if (!currentConversation) {
      alert('Please select or create a conversation first.');
      return;
    }

    try {
      const conversationFolderHandle = await conversationsHandle.getDirectoryHandle(currentConversation.name, { create: true });
      const assetsFolderHandle = await conversationFolderHandle.getDirectoryHandle('assets', { create: true });
      const imagePaths = extractImagePaths(codeContent);

      let modifiedCodeContent = codeContent;
      for (const imagePath of imagePaths) {
        if (imagePath.startsWith('/images/') || imagePath.startsWith('/assets/')) {
          try {
            const imageFileName = decodeURIComponent(imagePath.split('/').pop());
            const imageFileHandle = await foldersHandle.getDirectoryHandle('images').then(dir => dir.getFileHandle(imageFileName));
            const imageFile = await imageFileHandle.getFile();

            let newImagePath = `assets/${imageFileHandle.name}`;
            try {
              await assetsFolderHandle.getFileHandle(imageFileHandle.name, { create: false });
              console.log(`Image "${newImagePath}" already exists in assets. Skipping copy.`);
            } catch (err) {
              if (err.name === 'NotFoundError') {
                const newImageFileHandle = await assetsFolderHandle.getFileHandle(imageFileHandle.name, { create: true });
                const writable = await newImageFileHandle.createWritable();
                await writable.write(imageFile);
                await writable.close();
                console.log(`Copied image "${imageFileHandle.name}" to assets.`);
              } else {
                throw err;
              }
            }

            const escapedImagePath = escapeRegExp(imagePath);
            const regex = new RegExp(escapedImagePath, 'g');
            modifiedCodeContent = modifiedCodeContent.replace(regex, newImagePath);
          } catch (err) {
            console.error(`Image file ${imagePath} not found in 'folders/images' directory.`);
          }
        }
      }

      const indexFileHandle = await conversationFolderHandle.getFileHandle('index.html', { create: true });
      await writeEncryptedFile(indexFileHandle, modifiedCodeContent);

      console.log(`Code saved in conversations/${currentConversation.name}/index.html`);
    } catch (err) {
      console.error('Error saving code:', err);
      alert('Failed to save code.');
    }
  };

  const extractImagePaths = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.querySelectorAll('img');
    const imagePaths = [];

    images.forEach((img) => {
      let src = img.getAttribute('src');
      if (src && !src.startsWith('data:') && !src.startsWith('http')) {
        src = decodeURIComponent(src);
        imagePaths.push(src);
      }
    });

    return imagePaths;
  };

  const handleResetConversation = () => {
    if (window.confirm('Are you sure you want to reset the current conversation?')) {
      setMessages([]);
      setIndexContent(null);
      console.log('Conversation reset.');
      checkUserLimit();
    }
  };

  const handleUpgrade = () => {
    navigate('/subscribe');
    setIsLimitReached(false);
  };

  const openCreateFolderModal = () => {
    setNewFolderName('');
    setIsCreateFolderModalOpen(true);
  };

  const closeCreateFolderModal = () => {
    setIsCreateFolderModalOpen(false);
  };

  const handleCreateFolder = async () => {
    if (!foldersHandle) {
      alert('Folders directory handle is missing.');
      return;
    }

    if (!newFolderName.trim()) {
      alert('Folder name cannot be empty.');
      return;
    }

    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
    if (invalidChars.test(newFolderName)) {
      alert('Folder name contains invalid characters.');
      return;
    }

    if (newFolderName.toLowerCase() === 'images') {
      alert('A folder named "images" already exists and is reserved for image uploads.');
      return;
    }

    try {
      try {
        await foldersHandle.getDirectoryHandle(newFolderName);
        alert('A folder with this name already exists.');
        return;
      } catch (err) {}
      
      const newFolderHandle = await foldersHandle.getDirectoryHandle(newFolderName, { create: true });
      const folderMetadata = {
        name: newFolderName,
        createdAt: Date.now(),
        lastModified: Date.now(),
      };
      const metadataFileHandle = await newFolderHandle.getFileHandle('folder.json', { create: true });
      await writeEncryptedFile(metadataFileHandle, JSON.stringify(folderMetadata, null, 2));

      const newFolder = { name: newFolderName, folderHandle: newFolderHandle, lastModified: folderMetadata.lastModified };
      setFolders((prev) => [newFolder, ...prev]);
      alert(`Folder "${newFolderName}" created successfully.`);
      console.log(`Created folder: ${newFolderName}`);
      closeCreateFolderModal();
      setDisplayedFolders((prev) => prev + 1);
    } catch (err) {
      console.error('Error creating folder:', err);
      alert('Failed to create folder.');
    }
  };

  const toggleFolderExpansion = async (folderName) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));

    if (!expandedFolders[folderName] && !filesInFolders[folderName]) {
      try {
        const folderHandle = await foldersHandle.getDirectoryHandle(folderName);
        const files = [];
        for await (const entry of folderHandle.values()) {
          if (entry.kind === 'file') {
            files.push(entry.name);
          }
        }
        setFilesInFolders((prev) => ({
          ...prev,
          [folderName]: files,
        }));
        console.log(`Loaded files for folder "${folderName}":`, files);
      } catch (err) {
        console.error(`Error loading files for folder "${folderName}":`, err);
        alert(`Failed to load files for folder "${folderName}".`);
      }
    }
  };

  const handleSelectFile = async (folderName, fileName) => {
    const relativePath = `/${folderName}/${fileName}`;
    insertAtCursor('"' + relativePath + '" ');
    console.log(`Inserted file path: ${relativePath}`);
  };

  const insertAtCursor = (insertText) => {
    const textarea = document.getElementById('prompt-textarea');
    if (!textarea) return;

    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const before = prompt.substring(0, startPos);
    const after = prompt.substring(endPos, prompt.length);
    const newText = before + insertText + after;
    setPrompt(newText);

    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + insertText.length;
      textarea.focus();
    }, 0);
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === '/' && !e.ctrlKey && !e.altKey && !e.metaKey) {
      e.preventDefault();
    }
  };

  const replaceBlobURLsWithPaths = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const images = doc.querySelectorAll('img');
    images.forEach((img) => {
      let src = img.getAttribute('src');
      if (src && src.startsWith('blob:')) {
        const originalPath = blobURLToImagePathRef.current.get(src);
        if (originalPath) {
          img.setAttribute('src', originalPath);
        }
      }
    });

    return doc.documentElement.outerHTML;
  };

  const replaceRelativePathsWithBlobURLs = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.querySelectorAll('img');

    images.forEach((img) => {
      let src = img.getAttribute('src');
      if (src) {
        src = decodeURIComponent(src);
        if (src.startsWith('/images/') || src.startsWith('/assets/')) {
          const blobURL = imagePathToBlobURLRef.current.get(src);
          if (blobURL) {
            console.log(`Replacing ${src} with Blob URL: ${blobURL}`);
            img.setAttribute('src', blobURL);
          } else {
            console.warn(`No Blob URL found for image path: ${src}`);
            img.setAttribute('src', '/placeholder.png');
            img.setAttribute('alt', 'Image not found');
          }
        }
      }
    });

    const scriptContent = `
      <script>
        document.addEventListener('click', function() {
          window.parent.postMessage({ type: 'iframe-click' }, '*');
        });
      </script>
    `;
    const modifiedHTML = doc.documentElement.outerHTML.replace('</body>', `${scriptContent}</body>`);
    return modifiedHTML;
  };

  const getConversationIndexContent = async (conversation) => {
    if (!conversation || !conversationsHandle) {
      setIndexContent(null);
      return;
    }
    try {
      const conversationFolderHandle = await conversationsHandle.getDirectoryHandle(conversation.name);
      const indexFileHandle = await conversationFolderHandle.getFileHandle('index.html');
      const encryptedContent = await indexFileHandle.getFile().then(file => file.text());
      const decryptedContent = decryptData(encryptedContent);

      const assetsFolderHandle = await conversationFolderHandle.getDirectoryHandle('assets');
      const assetFiles = {};
      for await (const entry of assetsFolderHandle.values()) {
        if (entry.kind === 'file') {
          const assetFile = await entry.getFile();
          const assetBlobURL = URL.createObjectURL(assetFile);
          const assetPath = `assets/${entry.name}`;
          assetFiles[assetPath] = assetBlobURL;
        }
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(decryptedContent, 'text/html');
      const images = doc.querySelectorAll('img');
      images.forEach((img) => {
        let src = img.getAttribute('src');
        if (src) {
          src = decodeURIComponent(src);
          if (assetFiles[src]) {
            img.setAttribute('src', assetFiles[src]);
          } else {
            console.warn(`Image not found: ${src}`);
          }
        }
      });

      const modifiedHTML = doc.documentElement.outerHTML;
      const finalHTML = replaceRelativePathsWithBlobURLs(modifiedHTML);
      setIndexContent(finalHTML);
    } catch (err) {
      console.error('Error getting index.html content:', err);
      setIndexContent(null);
    }
  };

  useEffect(() => {
    const handleMessage = async (event) => {
      if (iframeRef.current && event.source === iframeRef.current.contentWindow) {
        const { type, payload } = event.data;

        switch (type) {
          case 'READ_FILE':
            try {
              const data = await readFile(payload.filename);
              event.source.postMessage({ type: 'FILE_CONTENT', payload: { filename: payload.filename, content: data } }, '*');
            } catch (err) {
              console.error(`Error reading file ${payload.filename}:`, err);
              event.source.postMessage({ type: 'ERROR', payload: `Failed to read file ${payload.filename}.` }, '*');
            }
            break;
          case 'WRITE_FILE':
            try {
              await writeFile(payload.filename, payload.content);
              event.source.postMessage({ type: 'WRITE_SUCCESS', payload: { filename: payload.filename } }, '*');
            } catch (err) {
              console.error(`Error writing to file ${payload.filename}:`, err);
              event.source.postMessage({ type: 'ERROR', payload: `Failed to write file ${payload.filename}.` }, '*');
            }
            break;
          default:
            console.warn('Unknown message type:', type);
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [currentConversation, conversationsHandle]);

  const readFile = async (filename) => {
    if (!currentConversation || !conversationsHandle) {
      throw new Error('No conversation selected.');
    }
    const conversationFolderHandle = await conversationsHandle.getDirectoryHandle(currentConversation.name);
    try {
      const fileHandle = await conversationFolderHandle.getFileHandle(filename);
      const decryptedContent = await readEncryptedFile(fileHandle);
      return decryptedContent;
    } catch (err) {
      console.warn(`${filename} not found. Returning empty content.`);
      return '';
    }
  };

  const writeFile = async (filename, content) => {
    if (!currentConversation || !conversationsHandle) {
      throw new Error('No conversation selected.');
    }
    const conversationFolderHandle = await conversationsHandle.getDirectoryHandle(currentConversation.name);
    const fileHandle = await conversationFolderHandle.getFileHandle(filename, { create: true });
    await writeEncryptedFile(fileHandle, content);
    console.log(`Data saved to ${filename}`);
  };

  const lastAssistantMessage = messages.slice().reverse().find((msg) => msg.sender === 'assistant');

  const handlePerFolderFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length === 0 || !currentUploadFolder) return;

    try {
      const folderHandle = await foldersHandle.getDirectoryHandle(currentUploadFolder, { create: true });
      const newUploadedFiles = [];
      const folderPathToBlobURL = imagePathToBlobURLRef.current;
      const blobURLToFolderPathMap = blobURLToImagePathRef.current;

      for (const file of files) {
        let fileName = file.name;
        let counter = 1;
        while (true) {
          try {
            await folderHandle.getFileHandle(fileName);
            const nameParts = fileName.split('.');
            const extension = nameParts.pop();
            const baseName = nameParts.join('.');
            fileName = `${baseName}(${counter}).${extension}`;
            counter += 1;
          } catch (err) {
            break;
          }
        }

        const newFileHandle = await folderHandle.getFileHandle(fileName, { create: true });
        const writable = await newFileHandle.createWritable();
        await writable.write(file);
        await writable.close();

        const fileURL = URL.createObjectURL(file);
        const relativePath = `/${currentUploadFolder}/${fileName}`;
        newUploadedFiles.push({ name: fileName, path: relativePath, url: fileURL });
        folderPathToBlobURL.set(relativePath, fileURL);
        blobURLToFolderPathMap.set(fileURL, relativePath);
        console.log(`Uploaded file: ${relativePath} -> ${fileURL}`);
      }

      setUploadedImages((prev) => [...prev, ...newUploadedFiles]);
      setFilesInFolders((prev) => ({
        ...prev,
        [currentUploadFolder]: [...(prev[currentUploadFolder] || []), ...newUploadedFiles.map(file => file.name)],
      }));

      alert('Files uploaded successfully!');
    } catch (err) {
      console.error('Error uploading files:', err);
      alert('Failed to upload files.');
    } finally {
      setCurrentUploadFolder(null);
      if (uploadFileInputRef.current) {
        uploadFileInputRef.current.value = '';
      }
    }
  };

  const toggleConversationsCollapse = () => {
    setIsConversationsCollapsed(!isConversationsCollapsed);
  };

  const toggleFoldersCollapse = () => {
    setIsFoldersCollapsed(!isFoldersCollapsed);
  };

  const toggleFavoriteAppsCollapse = () => {
    setIsFavoriteAppsCollapsed(!isFavoriteAppsCollapsed);
  };

  // **NEW: useEffect to Detect Source and Send to Backend**
  useEffect(() => {
    // Function to send source to backend
    const sendSourceToBackend = async (source) => {
      try {
        await api.post('/visit', { source, userId: user.id });
        console.log('Source sent to backend:', source);
      } catch (error) {
        console.error('Error sending source to backend:', error);
      }
    };

    // Function to extract UTM parameters
    const getUTMParams = () => {
      const params = new URLSearchParams(location.search);
      const utm_source = params.get('utm_source');
      const utm_medium = params.get('utm_medium');
      const utm_campaign = params.get('utm_campaign');
      // Add other UTM parameters if needed
      return { utm_source, utm_medium, utm_campaign };
    };

    // Function to determine source
    const determineSource = () => {
      const { utm_source } = getUTMParams();
      if (utm_source) {
        return utm_source;
      } else if (document.referrer) {
        try {
          const referrerUrl = new URL(document.referrer);
          const hostname = referrerUrl.hostname.replace('www.', '');
          const source = hostname.split('.')[0]; // e.g., 'twitter.com' -> 'twitter'
          return source;
        } catch (err) {
          console.error('Error parsing referrer URL:', err);
          return null;
        }
      }
      return null;
    };

    // Check if source has already been sent for this session
    const sourceSentFlag = `sourceSent_${user.id}`;
    if (!localStorage.getItem(sourceSentFlag)) {
      const source = determineSource();
      if (source) {
        console.log('source');
        console.log(source);
        
        sendSourceToBackend(source).then(() => {
          localStorage.setItem(sourceSentFlag, 'true');
        });
      }
    }
  }, [location.search, user.id]); // Run on location.search or user.id change

  // Send Click Count and Source (if any)
  const handleClick = () => {
    if (!hasDirectorySelected) return; // Only count clicks after directory selection

    const CLICK_THRESHOLD = 20;
    const userId = user.id;

    const currentCount = parseInt(localStorage.getItem('clickCount_' + userId)) || 0;
    const newCount = currentCount + 1;
    localStorage.setItem('clickCount_' + userId, newCount);
    console.log(`User ${userId} clicked. Current count: ${newCount}`);

    if (newCount >= CLICK_THRESHOLD) {
      const currentConversationCount = conversationCountRef.current;
      const currentFavoriteCount = favoriteConversations.length;

      sendClickCountToBackend(userId, CLICK_THRESHOLD, currentConversationCount, currentFavoriteCount)
        .then(() => {
          localStorage.setItem('clickCount_' + userId, newCount - CLICK_THRESHOLD);
          console.log(`Click count sent to backend. Remaining count: ${newCount - CLICK_THRESHOLD}`);
          checkUserLimit();
        })
        .catch((error) => {
          console.error('Error sending click count to backend:', error);
        });
    }
  };

  // Attach event listeners only after directory selection
  useEffect(() => {
    if (hasDirectorySelected) {
      document.addEventListener('click', handleClick);

      const handleMessageFromIframe = (event) => {
        if (event.data.type === 'iframe-click') {
          handleClick();
        }
      };

      window.addEventListener('message', handleMessageFromIframe);

      return () => {
        document.removeEventListener('click', handleClick);
        window.removeEventListener('message', handleMessageFromIframe);
      };
    }
  }, [hasDirectorySelected, user.id, favoriteConversations.length]);

  const sendClickCountToBackend = async (userId, clickCount, conversationCount, favoriteCount) => {
    try {
      await api.post('/clicks', { userId, clickCount, conversationCount, favoriteCount });
      console.log(`Sent ${clickCount} clicks, ${conversationCount} conversations, and ${favoriteCount} favorites for user ${userId} to backend.`);
    } catch (error) {
      console.error('Failed to send click count and conversation count:', error);
      throw error;
    }
  };

  // OpenAI API Key Management
  const openKeyModal = () => {
    if (user.id === "777777777") {
      setKeyInput(openaiApiKey);
    } else {
      setKeyInput('');
    }
    setIsKeyModalOpen(true);
  };

  const closeKeyModal = () => {
    setIsKeyModalOpen(false);
    setKeyInput('');
  };

  const handleSaveKey = async () => {
    if (!keyInput.trim()) {
      alert('API key cannot be empty.');
      return;
    }

    if (user.id === "777777777") {
      setOpenaiApiKey(keyInput.trim());
      localStorage.setItem('openaiApiKey', keyInput.trim());
      setIsKeyModalOpen(false);
      setKeyInput('');
      alert('OpenAI API key saved successfully.');
    } else {
      try {
        await api.post('/user/apiKey', { apiKey: keyInput.trim() });
        setHasApiKey(true);
        setIsKeyModalOpen(false);
        setKeyInput('');
        alert('OpenAI API key saved successfully.');
      } catch (err) {
        console.error('Error saving API key:', err);
        alert('Failed to save API key.');
      }
    }
  };

  return (
    <div className="chat-page">
      {isLimitReached && (
        <div className="limit-reached-bar">
          <span>You reached limits to continue using all features.</span>
          <button onClick={handleUpgrade} className="upgrade-account-btn">
            Upgrade Account
          </button>
        </div>
      )}

      <div className="usage-info">
        <p>Click Count: {currentClickCount} / {clickLimit}</p>
      </div>

      <div className="sidebar">
        <div className="openai-key-section">
          {user.id === "777777777" ? (
            openaiApiKey ? (
              <button className="key-present" onClick={openKeyModal}>OpenAI Key</button>
            ) : (
              <button className="add-key" onClick={openKeyModal}>Add OpenAI Key</button>
            )
          ) : (
            hasApiKey ? (
              <button className="key-present" onClick={openKeyModal}>OpenAI Key</button>
            ) : (
              <button className="add-key" onClick={openKeyModal}>Add OpenAI Key</button>
            )
          )}
        </div>

        <div className="sidebar-section conversations-section">
          <button variant="primary" onClick={handleOpenReview} className="btn review-btn">Write a Review</button>
          <ReviewModal show={reviewModalShow} handleClose={handleCloseReview} />

          <button variant="primary" onClick={handleOpenHelp} className="btn help-btn">Help</button>
          <HelpModal show={helpModalShow} handleClose={handleCloseHelp} />

          <button onClick={() => setIsShareModalOpen(true)} className="share-button btn">
            Share This App
          </button>
          <ShareModal
            isOpen={isShareModalOpen}
            onRequestClose={() => setIsShareModalOpen(false)}
            shareUrl={shareUrl}
            title={title}
            description={description}
          />

          <button onClick={handleSelectDirectory} className="btn" disabled={isLimitReached}>Select Directory</button>
          <button onClick={handleCreateConversation} className="btn" disabled={isLimitReached}>New Conversation</button>

          <div
            className="conversations-header"
            onClick={toggleConversationsCollapse}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '5px' }}
          >
            <h2>Conversations</h2>
            <button className="collapse-btn" style={{ background: 'none', border: 'none', fontSize: '1.2em' }}>
              {isConversationsCollapsed ? '+' : '-'}
            </button>
          </div>

          {!isConversationsCollapsed && (
            <>
              {conversations.length > 0 ? (
                <>
                  <ul className="conversation-list">
                    {conversations.slice(0, displayedConversations).map((convo, index) => (
                      <li
                        key={index}
                        className={`conversation-item ${
                          currentConversation && currentConversation.name === convo.name ? 'active' : ''
                        } ${isLimitReached && index >= 3 ? 'disabled-conversation' : ''}`}
                        onClick={() => handleSelectConversation(convo, index)}
                        style={isLimitReached && index >= 3 ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                      >
                        <span>{convo.name}</span>
                        <div className="conversation-actions">
                          {!isLimitReached && (
                            <>
                              <button
                                className="save-favorite-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFavoriteConversation(convo);
                                }}
                                title={favoriteConversations.includes(convo.name) ? 'Remove from Favorites' : 'Save to Favorites'}
                              >
                                {favoriteConversations.includes(convo.name) ? '★' : '☆'}
                              </button>
                              <button
                                className="rename-conversation-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openRenameConversationModal(convo);
                                }}
                                title="Rename Conversation"
                              >
                                ✏️
                              </button>
                              <button
                                className="delete-conversation-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteConversation(convo);
                                }}
                                title="Delete Conversation"
                              >
                                🗑️
                              </button>
                            </>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                  {displayedConversations < conversations.length && (
                    <button onClick={() => setDisplayedConversations((prev) => prev + 7)} className="btn load-more-btn" style={{ marginTop: '5px' }}>
                      Load More
                    </button>
                  )}
                </>
              ) : (
                <p>No conversations available.</p>
              )}
            </>
          )}
        </div>

        <div className="sidebar-section favorite-apps-section">
          <div
            className="favorite-apps-header"
            onClick={toggleFavoriteAppsCollapse}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '5px' }}
          >
            <h2>Favorite Apps</h2>
            <button className="collapse-btn" style={{ background: 'none', border: 'none', fontSize: '1.2em' }}>
              {isFavoriteAppsCollapsed ? '+' : '-'}
            </button>
          </div>

          {!isFavoriteAppsCollapsed && (
            <>
              {favoriteConversations.length > 0 ? (
                <ul className="favorite-apps-list">
                  {favoriteConversations
                    .map((favName) => conversations.find(c => c.name === favName))
                    .filter(convo => convo !== undefined)
                    .map((convo, index) => (
                      <li
                        key={index}
                        className={`favorite-conversation-item ${
                          currentConversation && currentConversation.name === convo.name ? 'active' : ''
                        }`}
                        onClick={() => handleSelectConversation(convo, conversations.indexOf(convo))}
                        style={isLimitReached && conversations.indexOf(convo) >= 3 ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                      >
                        {convo.name}
                        {!isLimitReached && (
                          <button
                            className="remove-favorite-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFavoriteConversation(convo.name);
                            }}
                            title="Remove from Favorites"
                          >
                            ✖️
                          </button>
                        )}
                      </li>
                    ))}
                </ul>
              ) : (
                <p>No favorite apps.</p>
              )}
            </>
          )}
        </div>

        <div className="sidebar-section folders-section">
          <button onClick={openCreateFolderModal} className="btn" disabled={isLimitReached}>
            Create New Folder
          </button>

          <div
            className="folders-header"
            onClick={toggleFoldersCollapse}
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '5px' }}
          >
            <h2>Folders</h2>
            <button className="collapse-btn" style={{ background: 'none', border: 'none', fontSize: '1.2em' }}>
              {isFoldersCollapsed ? '+' : '-'}
            </button>
          </div>

          {!isFoldersCollapsed && (
            <>
              {folders.length > 0 ? (
                <>
                  <ul className="folder-list">
                    {folders.slice(0, displayedFolders).map((folder, index) => (
                      <li key={index} className="folder-item">
                        <div className="folder-header" onClick={() => toggleFolderExpansion(folder.name)}>
                          <span className="folder-name">
                            {folder.name.toLowerCase() === 'images' ? `${folder.name} 📷` : folder.name}
                          </span>
                          <span className="folder-toggle">{expandedFolders[folder.name] ? '-' : '+'}</span>
                        </div>
                        {expandedFolders[folder.name] && (
                          <div className="folder-actions">
                            <button
                              onClick={() => {
                                setCurrentUploadFolder(folder.name);
                                if (uploadFileInputRef.current) {
                                  uploadFileInputRef.current.click();
                                }
                              }}
                              className="btn upload-btn"
                              disabled={isLimitReached}
                            >
                              Upload Files
                            </button>
                            <ul className="file-list">
                              {filesInFolders[folder.name] ? (
                                filesInFolders[folder.name].length > 0 ? (
                                  filesInFolders[folder.name].map((file, idx) => (
                                    <li key={idx} className="file-item">
                                      <button onClick={() => handleSelectFile(folder.name, file)} className="btn file-btn">
                                        {file}
                                      </button>
                                    </li>
                                  ))
                                ) : (
                                  <li className="no-files">No files in this folder.</li>
                                )
                              ) : (
                                <li className="loading-files">Loading files...</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  {displayedFolders < folders.length && (
                    <button
                      onClick={() => setDisplayedFolders((prev) => prev + 7)}
                      className="btn load-more-btn"
                      style={{ marginTop: '5px' }}
                    >
                      Load More
                    </button>
                  )}
                </>
              ) : (
                <p>No folders available.</p>
              )}
            </>
          )}
        </div>
      </div>

      <div className="main-chat">
        <h1>Chat Interface</h1>

        {isLoadingImages && (
          <div className="loading-indicator">
            <p>Loading images...</p>
          </div>
        )}

        <div className="messages-container">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              {msg.sender === 'user' ? (
                <div className="message-content">{msg.content}</div>
              ) : null}
            </div>
          ))}

          {lastAssistantMessage && indexContent && (
            <div className={`message assistant`}>
              <iframe
                ref={iframeRef}
                title={`Rendered Output`}
                className="rendered-iframe"
                srcDoc={indexContent}
                sandbox="allow-scripts allow-same-origin allow-downloads allow-popups allow-modals allow-forms"
              ></iframe>
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>

        <div className="input-area">
          <textarea
            id="prompt-textarea"
            placeholder="Enter your request (e.g., I want a landing page)... Type '/' to insert a file path."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleTextareaKeyDown}
            disabled={!currentConversation || loading || isLimitReached}
          />
          <div className="buttons">
            <button
              onClick={handleSend}
              disabled={loading || !currentConversation || isLimitReached}
              className="btn send-btn"
            >
              {loading ? 'Generating...' : 'Send'}
            </button>
            {currentConversation && (
              <button onClick={handleResetConversation} disabled={loading || isLimitReached} className="btn reset-btn">
                Reset
              </button>
            )}
          </div>
        </div>

        {/* Create Folder Modal */}
        <Modal
          isOpen={isCreateFolderModalOpen}
          onRequestClose={closeCreateFolderModal}
          contentLabel="Create New Folder"
          className="image-modal"
          overlayClassName="image-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>Create New Folder</h2>
            <input
              type="text"
              placeholder="Enter folder name"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              className="folder-input"
              disabled={isLimitReached}
            />
            <div className="modal-buttons">
              <button onClick={handleCreateFolder} className="btn create-folder-btn" disabled={isLimitReached}>
                Create
              </button>
              <button onClick={closeCreateFolderModal} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Rename Conversation Modal */}
        <Modal
          isOpen={isRenameModalOpen}
          onRequestClose={closeRenameConversationModal}
          contentLabel="Rename Conversation"
          className="image-modal"
          overlayClassName="image-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>Rename Conversation</h2>
            <input
              type="text"
              placeholder="Enter new conversation name"
              value={newConversationName}
              onChange={(e) => setNewConversationName(e.target.value)}
              className="folder-input"
              disabled={isLimitReached}
            />
            <div className="modal-buttons">
              <button onClick={handleRenameConversation} className="btn rename-conversation-btn" disabled={isLimitReached}>
                Rename
              </button>
              <button onClick={closeRenameConversationModal} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* API Key Modal */}
        <Modal
          isOpen={isKeyModalOpen}
          onRequestClose={closeKeyModal}
          contentLabel="Add OpenAI API Key"
          className="key-modal"
          overlayClassName="key-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>{user.id === "777777777" ? (openaiApiKey ? 'Update OpenAI API Key' : 'Add OpenAI API Key') : (hasApiKey ? 'Update OpenAI API Key' : 'Add OpenAI API Key')}</h2>
            <input
              type="text"
              placeholder="Enter your OpenAI API key"
              value={keyInput}
              onChange={(e) => setKeyInput(e.target.value)}
              className="key-input"
            />
            <div className="modal-buttons">
              <button onClick={handleSaveKey} className="btn save-key-btn">
                Save
              </button>
              <button onClick={closeKeyModal} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Hidden File Input for Uploads */}
        <input
          type="file"
          multiple
          ref={uploadFileInputRef}
          style={{ display: 'none' }}
          onChange={handlePerFolderFileUpload}
          disabled={isLimitReached}
        />

        {/* Error Message Display */}
        {error && (
          <div className="error-message">
            <p>{error}</p>
            {error === 'Request limit reached. Upgrade your account.' && (
              <button onClick={handleUpgrade} className="btn upgrade-btn">
                Upgrade Account
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
