// src/components/Modals/HelpModal.js
import React, { useState, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import api from '../../services/apiService';

const initialState = {
  userId: '',
  text: '',
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_ERRORS':
      return { ...state, errors: action.errors };
    case 'RESET':
      return initialState;
    case 'SET_INITIAL_USER_ID':
      return { ...state, userId: action.userId };
    default:
      return state;
  }
};

const HelpModal = ({ show, handleClose, onHelpSubmitted }) => {
  const { user, token } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { userId, text, errors } = state;

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const TEXT_MIN_LENGTH = 1;
  const TEXT_MAX_LENGTH = 500;

  // If authenticated, set the userId; if not, leave it blank
  useEffect(() => {
    if (user) {
      dispatch({ type: 'SET_INITIAL_USER_ID', userId: user.id });
    } else {
      dispatch({ type: 'SET_INITIAL_USER_ID', userId: '' });
    }
  }, [user]);

  const validate = () => {
    const validationErrors = {};

    // Only validate text length. userId isn't required since IP-based helps are allowed.
    const trimmedText = text.trim();
    if (!trimmedText) {
      validationErrors.text = 'Please enter your message.';
    } else if (trimmedText.length < TEXT_MIN_LENGTH) {
      validationErrors.text = `Message must be at least ${TEXT_MIN_LENGTH} characters.`;
    } else if (trimmedText.length > TEXT_MAX_LENGTH) {
      validationErrors.text = `Message cannot exceed ${TEXT_MAX_LENGTH} characters.`;
    }

    dispatch({ type: 'SET_ERRORS', errors: validationErrors });
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage('');
    setSuccessMessage('');

    if (!validate()) return;

    const helpData = {
      // Send userId if authenticated (the backend can handle if it's absent)
      ...(userId && { userId }),
      text: text.trim(),
    };

    setIsSubmitting(true);

    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      if (user && token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // Make sure /help-requests is the correct endpoint on your backend
      const response = await api.post('/helps', helpData, config);

      if (response.status === 201 || response.status === 200) {
        setSuccessMessage('Your help request has been submitted successfully.');
        dispatch({ type: 'RESET' });
        if (onHelpSubmitted) {
          onHelpSubmitted(response.data.data);
        }
      } else {
        setErrorMessage('Unexpected response from the server. Please try again.');
      }
    } catch (err) {
      console.error('Error submitting help request:', err);
      if (err.response?.data?.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage('There was an error submitting your request. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClose = () => {
    setErrorMessage('');
    setSuccessMessage('');
    dispatch({ type: 'RESET' });
    handleClose();
  };

  const canSubmit = !isSubmitting && text.trim().length >= TEXT_MIN_LENGTH && text.trim().length <= TEXT_MAX_LENGTH;

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      aria-labelledby="help-modal-title"
      backdrop="static"
      keyboard={!isSubmitting}
    >
      <Modal.Header closeButton={!isSubmitting}>
        <Modal.Title id="help-modal-title">Need Help?</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit} noValidate>
        <Modal.Body>
          {errorMessage && <Alert variant="danger" className="mb-3">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success" className="mb-3">{successMessage}</Alert>}

          {/* Hidden userId field (not required for unauthenticated) */}
          {user && (
            <Form.Group controlId="formUserId" className="d-none">
              <Form.Control type="text" value={userId} readOnly />
            </Form.Group>
          )}

          <Form.Group controlId="formText">
            <Form.Label>Message <span className="text-danger">*</span></Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Describe your issue or request for help..."
              value={text}
              onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'text', value: e.target.value })}
              isInvalid={!!errors.text}
              required
              maxLength={TEXT_MAX_LENGTH}
            />
            <Form.Control.Feedback type="invalid">
              {errors.text}
            </Form.Control.Feedback>
            <Row className="justify-content-between mt-1">
              <Col xs="auto" className="text-muted">
                {text.length}/{TEXT_MAX_LENGTH} characters
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={!canSubmit}>
            {isSubmitting ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                Submitting...
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

HelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onHelpSubmitted: PropTypes.func,
};

HelpModal.defaultProps = {
  onHelpSubmitted: null,
};

export default HelpModal;
