// src/components/common/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
// import Spinner from '../common/Spinner'; // Optional: Create a Spinner component

const PrivateRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    // Option 1: Simple loading message
    return <div>Loading...</div>;

    // Option 2: Use a Spinner component for better UX
    // return <Spinner />;
  }

  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
