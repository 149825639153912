import React from 'react';
import Modal from 'react-modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import './ShareModal.css'; // We'll create this file for styling

// Bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ShareModal = ({ isOpen, onRequestClose, shareUrl, title, description }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Share this app"
      className="share-modal"
      overlayClassName="share-modal-overlay"
    >
      <h2>Share This App</h2>
      <button onClick={onRequestClose} className="close-button">
        &times;
      </button>
      <div className="share-buttons">
        <FacebookShareButton url={shareUrl} quote={description}>
          <FacebookIcon size={48} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={48} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title} summary={description}>
          <LinkedinIcon size={48} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} title={title}>
          <WhatsappIcon size={48} round />
        </WhatsappShareButton>
      </div>
      <div className="share-link">
        <input type="text" value={shareUrl} readOnly onClick={(e) => e.target.select()} />
        <button onClick={() => navigator.clipboard.writeText(shareUrl)}>Copy Link</button>
      </div>
    </Modal>
  );
};

export default ShareModal;
